.loader {
    display:contents;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
  }
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left: 8px solid rgba(195, 27, 27, 0.808);; /* Blue color */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }